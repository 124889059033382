<template>
  <div>
    <div class="form--group photo--section">
      <div class="left--col">
        <div class="d-flex align-items-center">
          <span>{{ $t('addListing.floorplan') }}</span>
          <img src="@/assets/img/icons/info.svg" class="info--icon" id="floorPlanInfo" />
          <PopOverListingForm target="floorPlanInfo" :content="$t('addListing.hint.floorplan')" />
        </div>
      </div>
      <div class="right--col">
        <section-loading :show="loading" />
        <!--ini preview gambar-->
        <div class="photo--preview-wrapper" v-show="listPhoto && listPhoto.length > 0">
          <photo-preview
            v-for="(photo, index) in listPhoto"
            :key="index"
            :index="index"
            :length="listPhoto.length"
            :photo="photo"
            :isFloorPlan="true"
          />
        </div>
        <vue-dropzone
          v-show="false"
          ref="photoDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="added"
          @vdropzone-sending="sending"
          @vdropzone-success="success"
          @vdropzone-complete="completed"
          @vdropzone-error="onError"
        />
        <button
          :disabled="listPhoto.length >= 5"
          class="btn btn-primary--light btn-block"
          @click="triggerDropzone"
        >
          {{ $t('addListing.upload2') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'nuxt-dropzone';
import { mapState } from 'vuex';
const PhotoPreview = () => import('@/components/listing-form/sect1/photo-preview.vue');
import PopOverListingForm from '@/components/listing-form/pop-over-listing-form';
import SectionLoading from '@/components/content-loading/section-loading';

export default {
  name: 'floor-plan-photo-section',
  components: {
    vueDropzone: vue2Dropzone,
    PhotoPreview,
    PopOverListingForm,
    SectionLoading,
  },
  data() {
    return {
      addedPhoto: true,
      loading: false,
      dropzoneOptions: {
        url: `${this.$store.state.global.apiUrl}/api/v1/mylisting/uploadPhoto`,
        thumbnailWidth: 150,
        maxFilesize: 15,
        thumbnailMethod: 'contain',
        acceptedFiles: '.jpg,.png,.jpeg',
        // headers: {'X-CSRF-TOKEN': self.csrfToken},
        createImageThumbnails: true,
        timeout: 60000,
      },
    };
  },
  computed: {
    ...mapState({
      listPhoto: state => state.v2.listingForm.sect1.media.floorPlanList,
      apiUrl: state => state.global.apiUrl,
      form: state => state.v2.listingForm.form,
      user: state => state.global.user,
      listingType: state => state.v2.listingForm.listingType,
    }),
  },
  methods: {
    triggerDropzone() {
      this.$refs.photoDropzone.dropzone.hiddenFileInput.click();
    },
    completed() {
      this.loading = false;
    },
    added(file) {
      this.loading = true;
      const dropzone = this.$refs.photoDropzone;
      const maxFile = 5;
      const listPhoto = this.listPhoto.length;
      const uploading = dropzone.getUploadingFiles().length;
      const queued = dropzone.getQueuedFiles().length;
      const totalPhoto = listPhoto + uploading + queued;
      this.addedPhoto = true;
      if (totalPhoto >= maxFile) {
        dropzone.removeFile(file);
        // eslint-disable-next-line no-undef

        this.$swal(
          this.$t('errors.addListing.photo.maxTitle'),
          this.$i18n.t('errors.addListing.photo.maxMessage', {
            max: maxFile,
          }),
          'error',
        );
      }
    },
    // eslint-disable-next-line no-unused-vars
    sending(file, xhr, formData) {
      this.loading = true;
      xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.global.token}`);

      let self = this;
      // eslint-disable-next-line no-unused-vars
      xhr.ontimeout = function(e) {
        console.log('DROPZONE TIMEOUT!');
        self.loading = false;
        self.$swal({
          type: 'error',
          title: self.$t('general.swal.photo.timeoutT'),
          html: self.$t('general.swal.photo.timeoutD'),
          reverseButtons: true,
          confirmButtonText: 'OK',
        });
      };
    },
    success(file, response) {
      let img = file.previewElement.querySelector('img');
      this.$store.commit('v2/listingForm/sect1/media/ADD_FLOOR_PLAN', {
        fileName: img.alt,
        src: img.src,
        uuid: response,
        isPrimary: false,
      });
      let dropzone = this.$refs.photoDropzone;
      dropzone.removeFile(file);
    },
    // eslint-disable-next-line no-unused-vars
    onError(file, message, xhr) {
      this.loading = false;
      if (message && message.errors && message.errors.file && message.errors.file[0]) {
        this.$swal({
          type: 'error',
          title: 'Oops...',
          html: message.errors.file[0],
          reverseButtons: true,
          confirmButtonText: 'OK',
        });
      } else {
        this.$swal({
          type: 'error',
          title: 'Oops...',
          text: this.$t('general.swal.photo.errorD'),
          reverseButtons: true,
          confirmButtonText: 'OK',
        });
      }
    },
  },
};
</script>

<style scoped></style>
